import React from "react"

const Zigzag = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="5"
    viewBox="0 0 8.202 1.323"
    style={{ verticalAlign: "top" }}
  >
    <defs />
    <path
      fill="none"
      stroke={color ? color : "transparent"}
      stroke-width=".4"
      d="M.059 1.105L1.346.214l1.405.896L4.08.223l1.402.84 1.403-.85 1.26.808"
    />
  </svg>
)

export default Zigzag
