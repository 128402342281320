import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Appbar from "../Appbar"

import { IntlProvider } from "react-intl"
import "@formatjs/intl-pluralrules/polyfill"

import { getCurrentLangKey } from "ptz-i18n"
import { useDispatch } from "react-redux"
import InternationalizationActions from "../../store/actions/internationalizationActions"

// Messages
import en from "../../intl/translations/en.json"
import zh from "../../intl/translations/zh.json"
import { CssBaseline } from "@material-ui/core"
import { ThemeProvider } from "@material-ui/core/styles"
import zhutravelTheme from "./zhutravelTheme"

const Layout = ({
  children,
  location,
  pageContext,
  Textcolor,
  backgroundcolor,
  buttontextcolor,
  selected,
  button,
  isHeaderDisable,
}) => {
  const dispatch = useDispatch()

  const messages = { en, zh }
  const pageData = messages[pageContext.lang]
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          languages {
            defaultLangKey
            langs
          }
        }
      }
    }
  `)

  useEffect(() => {
    dispatch(
      InternationalizationActions.setLocaleData({
        localeObj: {
          locale: pageContext.locale,
          pageData: messages[pageContext.lang],
        },
      })
    )
  }, [dispatch, messages, pageContext.lang, pageContext.locale])

  const { langs, defaultLangKey } = data.site.siteMetadata.languages
  const langKey = getCurrentLangKey(langs, defaultLangKey, location.pathname)

  return (
    <IntlProvider locale={langKey} messages={messages}>
      <ThemeProvider theme={zhutravelTheme}>
        <CssBaseline />
        <div style={{ backgroundColor: backgroundcolor }}>
          {!isHeaderDisable && (
            <Appbar
              header={pageData?.header}
              backgroundcolor={backgroundcolor}
              Textcolor={Textcolor}
              selected={selected}
              button={button}
              location={location}
              buttontextcolor={buttontextcolor}
              pageContext={pageContext}
            />
          )}
          <main>{children}</main>
        </div>
      </ThemeProvider>
    </IntlProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.any.isRequired,
  messages: PropTypes.any.isRequired,
}

export default Layout
