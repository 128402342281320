// src/constants/locales.js

module.exports = {
  "en-US": {
    default: true,
    path: `en`,
    locale: `en-US`,
    dateFormat: `DD/MM/YYYY`,
    siteLanguage: `en`,
    ogLanguage: `en_US`,
    defaultTitle: `Using i18n with Gatsby`,
    defaultDescription: `Gatsby example site using MDX and dependency-free i18n`,
  },
  zh: {
    path: `zh`,
    locale: `zh`,
    dateFormat: `DD.MM.YYYY`,
    siteLanguage: `zh`,
    ogLanguage: `zh`,
    defaultTitle: `i18n mit Gatsby nutzen`,
    defaultDescription: `Gatsby Beispielseite, die MDX und i18n (frei von dependencies) nutzt`,
  },
}
