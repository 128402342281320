import React from "react"
import { Link } from "gatsby"
import locales from "../../intl/i18n.js"
import { useSelector } from "react-redux"

// Use the globally available context to choose the right path
const LocalizedLink = ({ to, ...props }) => {
  const { localeObj } = useSelector(state => state?.locale)
  const isIndex = to === `/`

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  const path = locales?.[localeObj?.locale]?.default
    ? to
    : `/${locales?.[localeObj?.locale]?.path}${isIndex ? `` : `${to}`}`

  return <Link {...props} to={path} />
}

export default LocalizedLink
