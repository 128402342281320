import { createMuiTheme } from "@material-ui/core/styles"
import Clearface from "../../styles/fonts/clearface.ttf"
import MabryProLight from "../../styles/fonts/mabry-light-pro.ttf"
import MabryProRegular from "../../styles/fonts/mabry-reg-pro.ttf"
import MabryProBold from "../../styles/fonts/mabry-medium-pro.ttf"

const clearface = {
  fontFamily: "Clearface",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Clearface'),
    local('Clearface-Regular'),
    url(${Clearface}) format('truetype')
  `,
}

const mabryproregular = {
  fontFamily: "MabryProRegular",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('MabryPro'),
    local('MabryPro-Regular'),
    url(${MabryProRegular}) format('truetype')
  `,
}
const mabryprobold = {
  fontFamily: "MabryProBold",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    url(${MabryProBold}) format('truetype')
  `,
}
const mabryprolight = {
  fontFamily: "MabryProLight",
  fontStyle: "normal",
  fontDisplay: "swap",
  src: `
    local('MabryPro'),
    local('MabryPro-Light'),
    url(${MabryProLight}) format('truetype')
  `,
}

const defaultTheme = createMuiTheme()

const zhutravelTheme = createMuiTheme({
  typography: {
    fontFamily: ["MabryProLight", "Helvetica", "Arial", "sans-serif"].join(","),
  },
  palette: {
    primary: {
      main: "#010204",
    },
    secondary: {
      main: "#010204",
    },
    text: {
      primary: "#010204",
      secondary: "#010204",
    },
    background: {
      default: "white",
    },
    action: {
      active: "#010204",
    },
  },
  shape: {
    borderRadius: 2,
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        "@font-face": [clearface, mabryproregular, mabryprolight, mabryprobold],
        a: {
          color: "#010204",
          textDecoration: "none",
        },
      },
    },
    MuiContainer: {
      root: {
        paddingLeft: defaultTheme.spacing(2.5),
        paddingRight: defaultTheme.spacing(2.5),
        [defaultTheme.breakpoints.up("sm")]: {
          paddingLeft: defaultTheme.spacing(7.5),
          paddingRight: defaultTheme.spacing(7.5),
        },
      },
    },
    MuiButton: {
      root: {
        textTransform: "none",
        fontFamily: "MabryProRegular",
        fontSize: "1rem",
        lineHeight: 1.5,
      },
      contained: {
        boxShadow: "none",
      },
      outlined: {
        borderColor: "rgba(1,2,4,0.1)",
      },
    },
    MuiAppBar: {
      root: {
        fontFamily: "MabryProRegular",
        fontSize: "1rem",
        lineHeight: 1,
      },
      colorPrimary: {
        color: "unset",
      },
    },
    MuiTypography: {
      h2: {
        fontFamily: "clearface",
        fontSize: "2.5rem",
        lineHeight: "44px",
        "& p": {
          margin: 0,
        },
      },
      h3: {
        fontFamily: "clearface",
        fontSize: "1.75rem",
      },
      h6: {
        fontFamily: "MabryProRegular",
        lineHeight: "26px",
      },
      overline: {
        fontFamily: "MabryProRegular",
        fontSize: "0.75rem",
        fontWeight: 600,
        lineHeight: "16px",
        letterSpacing: 0.5,
      },
      body1: {
        lineHeight: "20px",
      },
      body2: {
        fontFamily: "MabryProRegular",
        fontSize: "1rem",
      },
      subtitle1: {
        lineHeight: "20px",
        paddingTop: defaultTheme.spacing(1),
      },
    },
    MuiCardContent: {
      root: {
        "&:last-child": {
          paddingBottom: 16,
        },
      },
    },
  },
  props: {
    MuiButton: {
      disableElevation: true,
    },
    MuiContainer: {
      maxWidth: "xl",
    },
  },
})

zhutravelTheme.overrides.MuiTypography.h1 = {
  fontFamily: "clearface",
  letterSpacing: -0.2,
  fontSize: "2.5rem",
  [zhutravelTheme.breakpoints.up("md")]: {
    fontSize: "3.25rem",
  },
}

export default zhutravelTheme
