import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Menu,
  MenuItem,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Divider,
  ButtonGroup,
  Box,
  Hidden,
  SvgIcon,
  withWidth,
} from "@material-ui/core"
import TranslateIcon from "@material-ui/icons/Translate"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import CloseIcon from "@material-ui/icons/Close"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { Link } from "gatsby"
import useAppbarStyles from "./Appbar.styles"
import LocalizedLink from "../../components/LocalizedLink"
import { useSelector } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import Zigzag from "../Zigzag"
import Hamburger from "../Hamburger"

const Appbar = props => {
  const { header, selected } = props
  const classes = useAppbarStyles()
  const { menu } = header
  const { localeObj } = useSelector(state => state.locale)
  const Textcolor = props.Textcolor ? props.Textcolor : "#010204"
  const { basePath } = props.pageContext

  const currentPath =
    typeof window !== "undefined" ? window.location.pathname : ""

  const darkPages = ["/invest", "/WhyPortugal", "/GoldenVisa"]

  const useStyles = makeStyles(theme => ({
    navBox: {
      paddingLeft: theme.spacing(1.5),
      paddingRight: theme.spacing(1.5),
      paddingTop: theme.spacing(1),
      borderRadius: "5px",
      "&:hover": {
        backgroundColor:
          darkPages.indexOf(currentPath) > -1
            ? "rgba(255, 255, 255, 0.1)"
            : "rgba(1,2,4,0.1)",
      },
    },
  }))

  const classes2 = useStyles()

  const data = useStaticQuery(
    graphql`
      query {
        en: file(name: { eq: "logo-zhutravel-en-cropped" }) {
          childImageSharp {
            fixed(height: 36) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        zh: file(name: { eq: "logo-zhutravel-zh-cropped" }) {
          childImageSharp {
            fixed(height: 36) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        enWt: file(name: { eq: "logo-zhutravel-en-cropped-white" }) {
          childImageSharp {
            fixed(height: 36) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        zhWt: file(name: { eq: "logo-zhutravel-zh-cropped-white" }) {
          childImageSharp {
            fixed(height: 36) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    `
  )

  const menuData = [
    { labelKey: "tours", url: "/tours", color: "#FF9A8B" },
    { labelKey: "dmc", url: "/dmc", color: "#177C80" },
    { labelKey: "invest", url: "/invest", color: "#FAD578" },
    { labelKey: "about", url: "/about", color: "#010204" },
    { labelKey: "blog", url: "/blog", color: "#010204" },
  ]

  const options = [
    { locale: "en", label: "English" },
    { locale: "zh", label: "中文" },
  ]
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = Boolean(anchorEl)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }

  const [drawerOpen, setDrawerOpen] = useState(false)

  const handleDrawerOpen = () => {
    setDrawerOpen(true)
  }

  const handleDrawerClose = () => {
    setDrawerOpen(false)
  }

  const getLocalPath = lang => {
    return lang === "en"
      ? basePath
        ? "/" + basePath + "/"
        : props.pageContext.slug
      : `/${lang}${basePath ? "/" + basePath + "/" : props.pageContext.slug}`
  }

  return (
    <AppBar
      position="static"
      className={localeObj.locale === "zh" && "chinese-version"}
      color="transparent"
      elevation={0}
    >
      <Toolbar className={classes.root}>
        <LocalizedLink to="/">
          <Img
            fixed={
              selected === "invest"
                ? localeObj.locale === "zh"
                  ? data.zhWt.childImageSharp.fixed
                  : data.enWt.childImageSharp.fixed
                : localeObj.locale === "zh"
                ? data.zh.childImageSharp.fixed
                : data.en.childImageSharp.fixed
            }
          />
        </LocalizedLink>
        <Hidden mdUp>
          <IconButton onClick={handleDrawerOpen} edge="end">
            <SvgIcon>
              <Hamburger color={Textcolor} />
            </SvgIcon>
          </IconButton>
          <Drawer
            className={classes.drawer}
            anchor="right"
            open={drawerOpen}
            onClose={handleDrawerClose}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose}>
                <CloseIcon htmlColor="white" />
              </IconButton>
              <Typography
                style={{
                  fontFamily: "MabryProLight",
                  textTransform: "uppercase",
                  fontSize: "12px",
                  letterSpacing: "0.5px",
                }}
                display="inline"
              >
                {header["menuLabel"]}
              </Typography>
            </div>
            <Divider />
            <List>
              <ListItem
                button
                component={LocalizedLink}
                to="/"
                style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}
              >
                <ListItemText
                  primary={header["home"]}
                  disableTypography
                  style={{
                    fontFamily: "MabryProRegular",
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                />
              </ListItem>
              {menuData.map(item => (
                <ListItem
                  button
                  key={item.labelKey}
                  component={LocalizedLink}
                  to={item.url}
                  style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}
                >
                  <ListItemText
                    primary={header.menu[item.labelKey]}
                    disableTypography
                    style={{
                      fontFamily: "MabryProRegular",
                      fontSize: "16px",
                      lineHeight: "20px",
                    }}
                  />
                  <div className={classes.arrow}>
                    <ArrowForwardIcon />
                  </div>
                </ListItem>
              ))}
              <ListItem
                button
                component={LocalizedLink}
                to="/contact"
                style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.1)" }}
              >
                <ListItemText
                  primary={header.contact}
                  disableTypography
                  style={{
                    fontFamily: "MabryProRegular",
                    fontSize: "16px",
                    lineHeight: "20px",
                  }}
                />
                <div className={classes.arrow}>
                  <ArrowForwardIcon />
                </div>
              </ListItem>
            </List>
            <Box py={2} mx="auto">
              <ButtonGroup
                variant="contained"
                color="primary"
                aria-label="contained primary button group"
                disableElevation
              >
                {/* Map locales for langswitcher */}
                {options.map(locale => (
                  <Button
                    className={classes.langSwitcherMobile}
                    key={locale.locale}
                    component={Link}
                    to={getLocalPath(locale.locale)}
                    disabled={
                      // disable button if locale is same as current locale
                      locale.locale === props.pageContext.lang
                    }
                  >
                    <Typography
                      style={{
                        fontFamily: "MabryProRegular",
                        fontSize: "16px",
                        lineHeight: "20px",
                        color:
                          locale.locale === props.pageContext.lang
                            ? "#888"
                            : "#fff",
                      }}
                    >
                      {locale.label}
                    </Typography>
                  </Button>
                ))}
              </ButtonGroup>
            </Box>
          </Drawer>
        </Hidden>
        <Hidden smDown>
          <Toolbar disableGutters>
            <Toolbar disableGutters className={classes.navBlock}>
              {menuData.map(item => {
                return (
                  <Box
                    display="inline-block"
                    className={classes2.navBox}
                    component={LocalizedLink}
                    key={item.labelKey}
                    to={item.url}
                    style={{ color: props.Textcolor }}
                  >
                    {menu[item.labelKey]}
                    <Box className={classes.zigzag}>
                      <Zigzag
                        color={item.labelKey === selected ? item.color : null}
                      />
                    </Box>
                  </Box>
                )
              })}
              <Divider
                orientation="vertical"
                flexItem
                variant="middle"
                className={classes.divider}
                style={{
                  borderColor: props.Textcolor
                    ? props.Textcolor + "66"
                    : "rgba(1, 2, 4, 0.4)",
                }}
              />
            </Toolbar>
            <IconButton
              onClick={handleClick}
              edge="start"
              style={{
                borderRadius: "10px",
                padding: "7px",
                marginRight: "10px",
                marginLeft: "0px",
              }}
            >
              <TranslateIcon fontSize="small" style={{ color: Textcolor }} />
              <ExpandMoreIcon
                fontSize="small"
                style={{ color: Textcolor, opacity: "0.4" }}
              />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={anchorEl}
              keepMounted
              onClose={() => setAnchorEl(null)}
              open={open}
            >
              {options.map(option => {
                return (
                  <MenuItem
                    component={Link}
                    key={option}
                    disabled={option.locale === props.pageContext.lang}
                    to={getLocalPath(option.locale)}
                  >
                    {option.label}
                  </MenuItem>
                )
              })}
            </Menu>
            <LocalizedLink to="/contact">
              <Button
                variant={props.button ? "contained" : "outlined"}
                style={{
                  backgroundColor: props.button,
                  color: props.buttontextcolor,
                  fontFamily: "MabryProRegular",
                  fontSize: "16px",
                  lineHeight: "20px",
                }}
              >
                {header["contact"]}
              </Button>
            </LocalizedLink>
          </Toolbar>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

export default withWidth()(Appbar)
