import React from "react"

const Hamburger = ({ color }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 6.35 3.175"
  >
    <defs />
    <path
      fill="none"
      stroke={color}
      strokeWidth="0.3"
      d="M.073 3.035L1.108 2l1.034 1.036.996-1.004 1.073 1.004L5.246 2 6.28 3.035M.073 1.183L1.108.147l1.034 1.036L3.138.18l1.073 1.004L5.246.147 6.28 1.183"
    />
  </svg>
)

export default Hamburger
