import { makeStyles } from "@material-ui/core/styles"

const useAppbarStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    padding: `0 ${theme.spacing(2)}px`,
  },
  zigzag: {
    marginTop: 2,
    height: 5,
    textAlign: "center",
  },
  navBlock: {
    minHeight: "2em",
  },
  drawer: {
    color: "white",
  },
  drawerHeader: {
    color: "white",
    fontFamily: "MabryProBold",
  },
  drawerPaper: {
    width: 240,
    backgroundColor: "#1A1B1D",
    color: "white",
    fontFamily: "MabryProBold",
    padding: "1em",
  },
  langSwitcherMobile: {
    borderRadius: 2,
    fontFamily: "MabryProBold",
  },
  divider: {
    borderRight: "2px dotted rgba(1,2,4,0.4)",
    backgroundColor: "inherit",
  },
  arrow: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      backgroundColor: theme.palette.secondary.main,
      color: "#fff",
    },
    transition: "all 0.3s ease",
  },
}))
export default useAppbarStyles
